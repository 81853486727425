'use client';

import Image from 'next/image';
import Link from 'next/link';
import { IoHomeSharp } from 'react-icons/io5';
import React from 'react';
import { Button } from '../ui/button';
import { useTranslations } from 'next-intl';

const ErrorInformation = () => {
  const t = useTranslations();
  return (
    <div className="flex items-center justify-center border-b border-t border-gray-300 px-6 py-16 text-center">
      <div>
        <Image
          src="/assets/images/404.svg"
          alt="Error"
          width={600}
          height={400}
        />
        <h2 className="mt-6 text-2xl font-semibold">
          {t('something_went_wrong')}
        </h2>
        <p className="pb-7 pt-2 text-sm leading-7 md:pb-9 md:pt-3.5 md:text-base">
          {t('try_again_later')}
        </p>
        <Button asChild>
          <Link href="/">
            <IoHomeSharp className="mr-1" />
            {" "}
            {t('go_home')}
          </Link>
        </Button>
      </div>
    </div>
  );
};

export default ErrorInformation;
